<template>
    <div class="row">
        <b-form-group
            v-for="(field, index) in fields"
            v-bind:key="field.fieldToSave"
            :class="(typeof automateColumn === 'undefined') ? `col-md-${field.column}` : 'col-md'"
        >
            <label v-if="field.type === 'checkbox'" :for="field.fieldToSave+'-input'"></label>
            <label v-else-if="noLabel == false && field.type !== 'checkbox' && field.type !== 'labeltext'" :for="field.fieldToSave+'-input'">{{ field.label }}</label>
            <div v-if="field.disabled == true">
                <b v-if="field.type === 'comboautocomplete' || field.type === 'combosource'">{{ comboShowing[field.fieldToSave] }}</b>
                <b v-else>{{ formData[field.fieldToSave] }}</b>
            </div>
            <div v-else>
                <b-form-textarea
                    v-if="field.type === 'textarea'"
                    :placeholder="field.placeholder"
                    :state="formState(field.fieldToSave, index)"
                    :name="field.fieldToSave"
                    rows="5"
                    v-model="formData[field.fieldToSave]"
                />
                <multiselect
                    v-else-if="field.type === 'multiselect'"
                    :id="field.fieldToSave"
                    label="Name"
                    track-by="Name"
                    :placeholder="field.placeholder"
                    open-direction="bottom"
                    v-model="comboShowing[field.fieldToSave]"
                    :multiple="true"
                    :options="combo[field.fieldToSave].data"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :options-limit="300"
                    :show-no-results="true"
                    :hide-selected="true"
                    :allowEmpty="false"
                    @search-change="comboStore"
                    @select="comboSelect"
                    :class="(formState(field.fieldToSave, index)) ? 'is-valid-field' : 'is-invalid-field'"
                    :loading="loaderCombo[field.fieldToSave]"
                >
                    <template
                        slot="tag"
                        slot-scope="{ option }"
                    >
                        <span class="multiselect__tag">
                            <span>{{ option.Name }}</span>
                            <i @click="removeMultiselect(option, field.fieldToSave)" aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i>
                        </span>
                    </template>
                    <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="comboShowing[field.fieldToSave].length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                    </template>
                    <span slot="noResult">Oops! No elements found.</span>
                    <span slot="noOptions">Type something to get data</span>
                </multiselect>
                <vue-autosuggest
                    v-else-if="field.type === 'comboautocomplete'"
                    ref="autocomplete"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                        placeholder: field.placeholder,
                        class:(formState(field.fieldToSave, index)) ? 'form-control is-valid-vuesuggest' : 'form-control is-invalid-vuesuggest',
                        autocomplete:'off',
                        id: 'autocomplete_'+field.fieldToSave+'_'+index,
                    }"
                    @input="comboStore"
                    @focus="comboClick(field.fieldToSave, field.store, 'comboautocomplete', index); comboFocus(true, field.fieldToSave, index)"
                    @blur="comboFocus(false, field.fieldToSave, index)"
                    @selected="comboSelect"
                    :get-suggestion-value="comboGetSuggestionValue"
                    v-model="comboShowing[field.fieldToSave]"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                    <template slot="after-input">
                        <feather-icon
                            :style="(loaderCombo[field.fieldToSave]) ? 'display:none' : ''"
                            icon="ChevronDownIcon"
                            size="17"
                            class="align-middle combo-icon"
                        />
                        <div class="combo-prelabel-container" style="display:none" :id="'prelabel_'+field.fieldToSave+'_'+index">
                            <span class="combo-prelabel">
                                Type something to get data
                            </span>
                        </div>
                        <div class="combo-spinner-container" :style="(loaderCombo[field.fieldToSave]) ? '' : 'display:none'">
                            <div class="combo-spinner"></div>
                        </div>
                    </template>
                </vue-autosuggest>
                <vue-autosuggest
                    v-else-if="field.type === 'combosource'"
                    ref="combosource"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                        placeholder: field.placeholder,
                        class:(formState(field.fieldToSave, index)) ? 'form-control  is-valid-vuesuggest' : 'form-control is-invalid-vuesuggest',
                        autocomplete:'off',
                        id: 'autocomplete_'+field.fieldToSave+'_'+index,
                    }"
                    @input="comboSource"
                    @focus="comboClick(field.fieldToSave, field.store, 'combosource', index)"
                    @selected="comboSelect"
                    :get-suggestion-value="comboGetSuggestionValue"
                    v-model="comboShowing[field.fieldToSave]"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                    <template slot="after-input">
                        <feather-icon
                            :style="(loaderCombo[field.fieldToSave]) ? 'display:none' : ''"
                            icon="ChevronDownIcon"
                            size="17"
                            class="align-middle combo-icon"
                        />
                        <div class="combo-spinner-container" :style="(loaderCombo[field.fieldToSave]) ? '' : 'display:none'">
                            <div class="combo-spinner"></div>
                        </div>
                    </template>
                </vue-autosuggest>
                <b-form-checkbox
                    v-else-if="field.type === 'checkbox'"
                    v-model="checkboxes[field.fieldToSave]"
                    :name="field.fieldToSave"
                    value="check"
                    unchecked-value="uncheck"
                >
                    {{ field.label }}
                </b-form-checkbox>
                <b-form-datepicker
                    v-else-if="field.type === 'date'"
                    :name="field.fieldToSave"
                    :state="formState(field.fieldToSave, index)"
                    :placeholder="field.placeholder"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="id"
                    v-model="formData[field.fieldToSave]"
                />
                <b-form-timepicker
                    v-else-if="field.type === 'time'"
                    :name="field.fieldToSave"
                    :placeholder="field.placeholder"
                    :state="formState(field.fieldToSave, index)"
                    v-model="formData[field.fieldToSave]"
                />
                <flat-pickr
                    v-else-if="field.type === 'datetime'"
                    :name="field.fieldToSave"
                    :placeholder="field.placeholder"
                    v-model="formData[field.fieldToSave]"
                    :class="(formState(field.fieldToSave, index)) ? 'form-control  is-valid' : 'form-control is-invalid'"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', altInput: true, altFormat: 'd/m/Y H:i'}"
                />
                <cleave
                    v-else-if="field.numberFormat === true"
                    v-model="formData[field.fieldToSave]"
                    :class="formState(field.fieldToSave, index) ? 'form-control  is-valid' : 'form-control is-invalid'"
                    :raw="true"
                    :options="{
                        numeral: true,
                    }"
                    :placeholder="field.placeholder"
                    autocomplete="off"
                    :name="field.fieldToSave"
                />
                <span v-else-if="field.type === 'labeltext'" class="font-weight-bold">{{ field.label }}</span>
                <b-form-file
                    v-else-if="field.type === 'file'"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="fileSelected"
                    :name="field.fieldToSave"
                    required
                ></b-form-file>
                <b-form-input
                    v-else
                    :type="(field.type === 'password') ? 'password' : 'text'"
                    :placeholder="field.placeholder"
                    :state="formState(field.fieldToSave, index)"
                    autocomplete="off"
                    :name="field.fieldToSave"
                    v-model="formData[field.fieldToSave]"
                />
            </div>
            <span class="font-weight-bolder" style="font-size: 8pt" :id="'labelOf'+field.fieldToSave"></span>
        </b-form-group>
    </div>
</template>

<script>
/* eslint-disable */
import { BModal, BCardText, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormDatepicker, BFormTimepicker, BFormFile } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import { VueAutosuggest } from 'vue-autosuggest'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
    name: 'Fields',
    props: ['fields','data','automateColumn','noLabel','type'],
    components: {
        BCardText,
        BModal,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BFormDatepicker,
        BFormTimepicker,
        VueAutosuggest,
        Cleave,
        BFormFile,
        Multiselect,
        flatPickr,
    },
    watch: {
        fields: function(){
            this.reset()
        }
    },
    data() {
        return {
            formData: {},
            combo: {},
            activeCombo: '',
            activeComboIndex: '',
            urlCombo: '',
            comboSelected: {},
            comboShowing: {},
            checkboxes: {},
            multiselectStore : {},
            bodyType: 'json',
            loaderCombo: {}
        }
    },
    created() {
        if(this.fields.length > 0) this.reset()
        setTimeout(() => {
            if((typeof this.automateColumn !== 'undefined')) this.reset()
        },800)
    },
    methods: {
        reset() {
            this.formData = {}
            this.combo = {}
            this.activeCombo = ''
            this.activeComboIndex = ''
            this.urlCombo = ''
            this.comboSelected = {}
            this.comboShowing = {}
            this.checkboxes = {}
            this.multiselectStore = {}
        
            this.load()
        },

        load() {
            if(typeof this.noLabel === 'undefined') this.noLabel = false
            this.fields.map((value, key) => {
                let dataFilled = false

                if(typeof this.data !== 'undefined' && Object.keys(this.data).length > 0 && this.data[value.fieldToSave] !== null) {
                    this.$set(this.formData, value.fieldToSave, this.data[value.fieldToSave])
                    dataFilled = true
                } else this.$set(this.formData, value.fieldToSave, undefined)

                if(value.type == 'multiselect') {
                    this.fillCombo(value.fieldToSave, [])
                    this.$set(this.multiselectStore, value.fieldToSave, value.store)
                    this.$set(this.loaderCombo, value.fieldToSave, false)
                    const formData = []
                    const comboShowing = []
                    if(dataFilled) {
                        this.data[value.fieldToSave].map((value, key) => {
                            formData.push(value.Oid)
                            comboShowing.push(value)
                        })
                    }
                    this.$set(this.formData, value.fieldToSave, formData)
                    this.$set(this.comboShowing, value.fieldToSave, comboShowing)
                } else if(value.type == 'combosource') {
                    if(dataFilled) {
                        if(typeof this.data[value.fieldToSave] !== 'undefined' && this.data[value.fieldToSave] !== null && typeof this.data[value.fieldToSave].selected !== 'undefined') {
                            this.formData[value.fieldToSave] = this.data[value.fieldToSave].selected.Oid
                            this.comboShowing[value.fieldToSave] = this.data[value.fieldToSave].selected.Name
                        } else if(typeof this.data[value.fieldToSave] !== 'undefined') {
                            this.formData[value.fieldToSave] = this.data[value.fieldToSave].Oid
                            this.comboShowing[value.fieldToSave] = this.data[value.fieldToSave].Name
                        }
                    }
                    this.$set(this.loaderCombo, value.fieldToSave, false)
                    this.fillCombo(value.fieldToSave, value.source)
                } else if (value.type == 'comboautocomplete') {
                    if(dataFilled && this.data[value.fieldToSave] !== null) {
                        this.fillCombo(value.fieldToSave, [this.data[value.fieldToSave]])
                        this.formData[value.fieldToSave] = this.data[value.fieldToSave].Oid
                        this.comboShowing[value.fieldToSave] = this.data[value.fieldToSave].Name
                    } else this.fillCombo(value.fieldToSave, [])
                    this.$set(this.loaderCombo, value.fieldToSave, false)
                } else if (value.type == 'checkbox') {
                    if(dataFilled) {
                        if(parseInt(this.data[value.fieldToSave]) === 1) this.fillCheckbox(value.fieldToSave, 'check')
                        else this.fillCheckbox(value.fieldToSave, 'uncheck')
                    } else this.fillCheckbox(value.fieldToSave, 'uncheck')
                } else if (value.type == 'file') {
                    this.bodyType = 'formdata'
                }

                if(!dataFilled) {
                    if(value.type == 'combosource' || value.type == 'comboautocomplete') {
                        if(typeof value.defaultValue !== 'undefined' && typeof value.defaultValue.Oid !== 'undefined') {
                            this.formData[value.fieldToSave] = value.defaultValue.Oid
                            this.comboShowing[value.fieldToSave] = value.defaultValue.Name
                        }
                    } else if(value.type == 'checkbox') {
                        if(typeof value.defaultValue !== 'undefined') {
                            if(parseInt(value.defaultValue) === 1) this.fillCheckbox(value.fieldToSave, 'check')
                            else this.fillCheckbox(value.fieldToSave, 'uncheck')
                        }
                    } else if(value.type == 'multiselect') {
                        const formData = []
                        const comboShowing = []
                        value.defaultValue.map((value, key) => {
                            formData.push(value.Oid)
                            comboShowing.push(value)
                        })
                        this.formData[value.fieldToSave] = formData
                        this.comboShowing[value.fieldToSave] = comboShowing
                    } else {
                        this.setDefaultValue(value.fieldToSave, value.defaultValue)
                    }
                } 
                this.handleState()
            })
        },

        fillCombo(fieldToSave, source) {
            let tmp = { data: source, raw: source }
            this.$set(this.combo, fieldToSave, tmp)
        },

        fillCheckbox(fieldToSave, value) {
            this.$set(this.checkboxes, fieldToSave, value)
            this.formData[fieldToSave] = (value === 'uncheck') ? 0 : 1
        },

        setDefaultValue(fieldToSave, value) {
            if(typeof value !== 'undefined') {
                if(value == 'NOW()') {
                    const currentDate = moment(new Date()).format('Y-M-D HH:mm')
                    this.formData[fieldToSave] = currentDate
                } else this.formData[fieldToSave] = value
            }
        },

        handleState() {
            let validate = this.formData
            this.fields.map((value, key) => {
                if(value.required === 1) {
                    if(typeof this.formData[value.fieldToSave] == 'undefined' || this.formData[value.fieldToSave] == '') {
                        validate = false
                    }
                }

                if(value.type == 'checkbox') {
                    if(this.checkboxes[value.fieldToSave] == 'check') this.formData[value.fieldToSave] = 1
                    else this.formData[value.fieldToSave] = 0
                }
            })
            
            return validate
        },
        
        formState(fieldToSave, index) {
            if(this.fields[index].required === 1) {
                if(typeof this.formData[fieldToSave] == 'undefined' || this.formData[fieldToSave] == '') {
                    return false
                }
            }
            return true
        },

        getComboSourceSelectedIndex(fieldToSave) {
            let index = 0
            let selectedIndex
            this.combo[fieldToSave].data.map((value,key) => {
                if(value.Oid === this.comboSelected[fieldToSave]) selectedIndex = index
                else index++
            })

            return selectedIndex
        },

        comboFocus(state, fieldToSave, index) {
            const el = document.getElementById('prelabel_'+fieldToSave+'_'+index)
            if(state == true && this.combo[fieldToSave].data.length < 1) el.style.display = 'flex'
            else el.style.display = 'none'
        },

        comboClick(fieldToSave, store = undefined, type = undefined, index) {
            this.formData[fieldToSave] = undefined
            delete this.comboShowing[fieldToSave]
            this.activeCombo = fieldToSave
            if(store !== undefined) this.urlCombo = store
            if(type === 'combosource' && store !== undefined) this.comboAutoComplete(store, '', fieldToSave)

            const el = document.getElementById('autocomplete_'+fieldToSave+'_'+index)
            el.click()
        },

        comboSelect(item) {
            if(typeof item.Oid !== 'undefined') {
                this.formData[this.activeCombo].push(item.Oid)
            } else {
                this.comboSelected[this.activeCombo] = item.item.Oid
                this.formData[this.activeCombo] = item.item.Oid
                
                if(typeof item.item.Link !== 'undefined') {
                    let keys = Object.keys(item.item.Link)
                    keys.map((value,key) => {
                        if(typeof item.item.Link[value].Oid !== 'undefined') {
                            this.fillCombo(value, [item.item.Link[value]])
                            this.formData[value] = item.item.Link[value].Oid
                            this.comboShowing[value] = item.item.Link[value].Name
                        } else if(typeof item.item.Link[value] == 'object') this.fillCombo(value, item.item.Link[value])
                        else this.formData[value] = item.item.Link[value]
                    })
                }

                const labelComponent = document.getElementById('labelOf' + this.activeCombo)
                labelComponent.innerHTML = ''
                labelComponent.classList.remove('text-danger')
                labelComponent.classList.remove('text-primary')
                labelComponent.classList.remove('text-warning')
                labelComponent.classList.remove('text-secondary')
                labelComponent.classList.remove('text-success')
                labelComponent.classList.remove('text-info')
                labelComponent.classList.remove('text-light')
                labelComponent.classList.remove('text-dark')
                labelComponent.classList.remove('text-muted')
                labelComponent.classList.remove('text-white')
                if(typeof item.item.Label !== 'undefined') {
                    labelComponent.innerHTML = item.item.Label.message
                    labelComponent.classList.add('text-'+item.item.Label.variant)
                }
            }
        },

        comboGetSuggestionValue(selected) {
            return selected.item.Name
        },

        comboStore(keyword, id = null) {
            if(id) {
                this.activeCombo = id
                this.urlCombo = this.multiselectStore[id]
            }
            
            if(keyword.length < 3) {
                this.combo[this.activeCombo] = { data: [], raw: [] }
            } else {
                this.comboAutoComplete(this.urlCombo, keyword, this.activeCombo)
            }
        },

        comboSource(keyword) {
            if(keyword === '' || keyword === undefined) this.combo[this.activeCombo].data = this.combo[this.activeCombo].raw

            const filteredData = this.combo[this.activeCombo].raw.filter(item => {
                return item.Name.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
            });
            
            this.combo[this.activeCombo].data = filteredData
        },

        comboAutoComplete(storeUrl, keyword, fieldToSave) {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                this.loaderCombo[fieldToSave] = true
                const params = { keyword : keyword }
                this.$http.get(storeUrl, { params : params })
                .then(res => {
                    const tmp = { data: res.data, raw: res.data }
                    this.combo[fieldToSave] = tmp
                    
                    this.loaderCombo[fieldToSave] = false

                    const prelabels = document.getElementsByClassName('combo-prelabel-container')
                    for(const prelabel of prelabels) prelabel.style.display = 'none'
                })
            }, 1000)
        },

        fileSelected(event) {
            this.formData[event.srcElement.name] = event.target.files[0]
        },

        removeMultiselect(option,fieldToSave) {
            let index = this.formData[fieldToSave].indexOf(option.Oid)
            this.formData[fieldToSave].splice(index, 1)
            this.comboShowing[fieldToSave].splice(index, 1)
        },
    },
}
/* eslint-enable */
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style>
    .is-invalid-field .multiselect__tags, .is-invalid-vuesuggest {
        background-position: right calc(1.8625em + 0.219rem) center;
        padding-right: calc(1.45em + 0.876rem);
        border-color: #ea5455 !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    }
    .is-valid-field .multiselect__tags, .is-valid-vuesuggest {
        background-position: right calc(1.8625em + 0.219rem) center;
        padding-right: calc(1.45em + 0.876rem);
        border-color: #28c76f !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    }
    .autosuggest__results-container .autosuggest__results {
        margin-top: 0 !important;
    }
    .combo-icon {
        position:absolute;
        top: 0;
        margin-top: 35px;
        right: 0;
        margin-right: 22px;
    }
</style>
<style scoped>
    .combo-prelabel-container {
        position: relative;
        z-index: 999;
    }
    .combo-prelabel-container .combo-prelabel {
        padding:10px;
        position:absolute;
        height: 40px;
        width: 100%;
        border-radius: .3rem;
        display: flex;
        align-items: center;
        background: #fff;
        box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
    }
    .combo-spinner-container {
        position: absolute;
        top: 0;
        margin-top: 33px;
        right: 0;
        margin-right: 22px;
    }
    .combo-spinner-container .combo-spinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #00ae67;
        border-right: 4px solid #00ae67;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
