<template>
  <div>
    <h3>DASHBOARD</h3>
    <!-- CONTENT -->
    <div class="mt-3">
      <div class="row" v-for="(item, key) in items" v-bind:key="key">
        <div v-for="(widget, key) in item" v-bind:key="key" :class="widget.column">
          <!-- GREETING -->
          <div v-if="widget.type == 'greeting'" class="card greeting-card">
            <div class="card-body d-flex justify-content-between">
              <h3>{{ widget.content.greeting }}</h3>
              <img :src="widget.content.image" height="100">
            </div>
          </div>
          <!-- INFO BOX -->
          <div v-if="widget.type == 'infoBox'" :class="'card infobox-card bg-' + widget.variant">
            <div class="card-body d-flex justify-content-between">
              <div>
                <span class="text-white font-weight-bold">{{ widget.content.title }}</span>
                <h3 class="text-white font-weight-bolder">{{ widget.content.value }}</h3>
              </div>
              <feather-icon
                :icon="widget.content.icon"
                size="40"
                class="text-white"
              />
            </div>
          </div>
          <!-- CHARTS -->
          <div v-if="widget.type == 'chart'" class="card chart-card">
            <div class="card-header">
              <h3 class="card-title">{{ widget.content.title }}</h3>
            </div>
            <div class="card-body">
              <chartjs-component-bar-chart
                v-if="widget.variant == 'bar'"
                :height="200"
                :data="{labels: widget.content.labels, datasets: [ {label: widget.content.title, backgroundColor: '#7367f0', data: widget.content.data} ]}"
              />
            </div>
          </div>
          <!-- FORM -->
          <div v-if="widget.type == 'form'" class="card form-card">
            <div class="card-body">
              <field v-if="widget.content.length > 0" :fields="widget.content" ref="fieldComponent"/>
              <button class="btn btn-primary btn-sm" @click="formSubmit()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import ChartjsComponentBarChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue'
import Field from '@/views/Admin/components/Field.vue'

export default {
  components: {
    ChartjsComponentBarChart,
    Field
  },

  data() {
    return {
      items: []
    }
  },

  created() {
    this.checkCredential()
  },

  methods: {
    checkCredential() {
      this.$http.get('credential')
      .then(res => {
        if(res.data != 'OK') this.errorAuth(res.data)
        else this.load()
        this.loaderState(false)
      })
      .catch(err => this.loaderState(false))
    },

    load() {
      document.title = 'Dashboard'
      this.$http.get('/general/dashboard')
      .then(res => {
        this.items = res.data
      })
    },

    formSubmit() {
      let validate = this.$refs.fieldComponent[0].handleState()
      if(validate !== false) {
        this.loaderState(true)
        this.items = []
        const formData = validate
        this.$http.get('/general/dashboard', { params: formData })
        .then(res => {
          this.items = res.data
          this.loaderState(false)
        })
        .catch(err => this.loaderState(false))
      }
    },
  }

}
</script>
<style scoped>

</style>
